<template>
  <div class="">
    <div class="page-content">
      <!-- 个人信息 -->
      <div class="person" id="gerenname" v-if="!gerenstuts && resumeInfo.user">
        <div class="p-pic">
          <img
              v-if="resumeInfo.user.avatar"
              :src="
            'https://freemen.work/user/file/view/' + resumeInfo.user.avatar
            "
              @click="addtouxiang()"
          />
          <!-- // 'http://192.168.10.174:8200/user/file/view/' + resumeInfo.user.avatar  -->
          <img
            v-else
            src="../../../assets/images/mycenter/yishiming.png"
            @click="addtouxiang()"
          />
        </div>
        <div class="p-info">
          <div class="pi-one">
            <div class="font20">
              <span>{{ resumeInfo.user.displayName }}</span>
              <img
                src="../../../assets/images/common/boy.png"
                v-if="resumeInfo.user.sex == 1"
              />
              <img src="../../../assets/images/common/girl.png" v-else />
            </div>
            <div class="">
              <img
                class="edit"
                @click="gerenstuts = !gerenstuts"
                src="../../../assets/images/mycenter/editor.png"
              />
            </div>
          </div>
          <div class="pi-two font16">
            {{
              new Date().getYear() -
              new Date(resumeInfo.user.birthday).getYear()
            }}岁 | {{ resumeInfo.user.educationType }}
          </div>
          <div class="pi-three font16">
            <img
              class="zhiwei"
              src="../../../assets/images/mycenter/zhiwei.png"
            />
            {{ resumeInfo.userCv.workStatus == 1 ? "在职" : "求职" }}
            <img
              class="dianhua"
              src="../../../assets/images/mycenter/dianhua.png"
            />
            {{ resumeInfo.user.phone }}
            <img
              class="youxiang"
              src="../../../assets/images/mycenter/youxiang.png"
            />
            {{ resumeInfo.user.email }}
            <img
              class="weixin"
              src="../../../assets/images/mycenter/weixin.png"
            />
            {{ resumeInfo.user.wxNumber }}
          </div>
        </div>
      </div>
      <div class="gereninfo" v-if="gerenstuts">
        <div class="p-tit font18">编辑个人信息</div>
        <div class="formbox">
          <el-form
            :model="user"
            ref="user"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="姓名："
              prop="displayName"
              :rules="[{ required: true, message: '年龄不能为空' }]"
            >
              <el-input
                v-model="user.displayName"
                placeholder="请输入姓名"
                style="width: 260px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="出生年月："
              prop="birthday"
              :rules="[{ required: true, message: '出生年月不能为空' }]"
            >
              <el-date-picker
                v-model="user.birthday"
                :picker-options="pickerOptions"
                type="date"
                placeholder="出生年月"
                style="width: 260px"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="性别:" prop="sex">
              <el-select
                v-model="user.sex"
                placeholder="请选择性别"
                style="width: 260px"
              >
                <el-option label="男" :value="1"></el-option>
                <el-option label="女" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号码：">
              <el-input
                v-model="phone"
                disabled=""
                placeholder="请输入手机号码"
                style="width: 260px"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="电子邮箱："
              prop="email"
              :rules="emailrules.email"
            >
              <el-input
                v-model="user.email"
                placeholder="请输入电子邮箱"
                style="width: 650px"
              ></el-input>
            </el-form-item>
            <el-form-item
                label="微信号："
                prop="wxNumber"
                :rules="[{ required: true, message: '请输入您的微信号' }]"
            >
              <el-input
                v-model="user.wxNumber"
                placeholder="请输入您的微信号"
                style="width: 260px"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="qux" @click="gerenstuts = !gerenstuts"
                >取消</el-button
              >
              <el-button class="que" type="primary" @click="submitForm('user')"
                >保存</el-button
              >
            </el-form-item>
            <div class="font14" style="color: #999999; margin-left: 100px">
              填写微信号能让您与企业握手后更好的沟通
            </div>
          </el-form>
        </div>
      </div>

      <!-- 求职意向 -->
      <div class="qiuzhi" id="qiuzhi" v-if="zhiweistuts">
        <div class="p-tit font18">
          {{ type == 1 ? "添加" : "编辑" }}求职意向
        </div>
        <div class="formbox">
          <el-form
            :model="userJob"
            ref="userJob"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="职位名称："
              prop="jobName"
              :rules="[{ required: true, message: '请输入职位名称' }]"
            >
              <el-input
                v-model="userJob.jobName"
                placeholder="请输入职位名称"
                style="width: 650px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="期望工作时间："
              prop="endDate"
              :rules="[{ required: true, message: '期望工作时间不能为空' }]"
            >
              <el-date-picker
                v-model="userJob.startDate"
                type="date"
                placeholder="选择日期"
                @change="dateStart"
                style="width: 315px; margin-right: 20px"
              >
              </el-date-picker>
              <el-date-picker
                v-model="userJob.endDate"
                type="date"
                placeholder="选择日期"
                @change="dateEnd"
                style="width: 315px"
              >
              </el-date-picker>
            </el-form-item>
            <!-- <el-form-item
              label="工作城市："
              prop="city"
              :rules="[{ required: true, message: '请选择工作城市' }]"
            >
              <el-select
                v-model="userJob.city"
                placeholder="请选择城市"
                style="width: 650px"
              >
                <el-option
                  v-for="item in cityOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item
              label="工作城市："
              prop="city"
              :rules="[{ required: true, message: '请选择工作城市' }]"
            >
              <el-cascader
                :options="options"
                v-model="cityCatch[0]"
                :props="{ expandTrigger: 'hover' }"
                @change="citySelector"
                style="width: 260px">
              </el-cascader>
            </el-form-item>
            <el-form-item
                label="第二意向城市："
                prop="city"
                :rules="[{ required: false, message: '请选择工作城市' }]"
            >
              <el-cascader
                  :options="options"
                  v-model="cityCatch[1]"
                  :props="{ expandTrigger: 'hover' }"
                  @change="city2Selector"
                  style="width: 260px">
              </el-cascader>
            </el-form-item>
            <el-form-item
              label="工作技能："
              prop="skillIds"
              :rules="[{ required: true, message: '请选择工作技能' }]"
            >
              <el-select
                  v-model="skillType"
                  placeholder="请选择技能类型"
                  style="width: 260px; margin-right: 30px"
                  @change="onSkillTypeChanged"
              >
                <el-option
                    v-for="item in skillTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </el-select>

              <el-select
                :value="skillNames.join(',')"
                placeholder="请选择工作技能"
                style="width: 260px;"
                @click.native="choosejineg()"
              >
                <!-- <el-option v-for="item in gzoptions" :key="item.value" :label="item.label" :value="item.value"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item
              label="工作经验："
              prop="expYear"
              :rules="[{ required: true, message: '请选择工作经验' }]"
            >
              <el-select
                v-model="userJob.expYear"
                placeholder="请选择工作经验"
                style="width: 260px"
              >
                <el-option
                  v-for="item in expOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="期望薪资："
              prop="salaryHigh"
              :rules="[{ required: true, message: '期望薪资' }]"
            >
              <el-select
                v-model="userJob.salaryLow"
                placeholder="最低"
                style="width: 260px; margin-right: 30px"
              >
                <el-option
                  v-for="item in gzLowOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-select
                v-model="userJob.salaryHigh"
                placeholder="最高"
                style="width: 260px"
              >
                <template v-for="item in gzHighOptions">
                  <el-option
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    v-if="item.value > userJob.salaryLow"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button class="quxb" @click="zhiweistuts = false"
                >取消</el-button
              >
              <el-button
                class="que"
                type="primary"
                @click="submitForm('userJob')"
                >保存</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!--  -->
      <div class="gonggong" id="qiuzhi" v-if="!zhiweistuts">
        <div class="tit-header font18">
          <div class="p-tit">求职意向</div>
          <div class="caozuo font14" @click="addWantJob">
            <img src="../../../assets/images/mycenter/add.png" />
            添加
          </div>
        </div>
        <div
          class=""
          style="border-left: 1px solid #f3f3f3; border-bottom: 1px solid #f3f3f3; padding: 0 5px 10px 5px"
          v-for="(item, index) in resumeInfo.userJobList"
          :key="index"
        >
          <div class="position">
            <div class="p-left font18">
              {{ item.jobName }}
              <span class="font14">
                {{ (item.salaryLow / 1000) | numFilter }}K - {{ (item.salaryHigh / 1000) | numFilter }}K | {{ item.city }}
              </span>
            </div>
            <div class="p-center font18" style="justify-content: flex-end; flex: 2;">期望工作时间</div>
            <div class="p-right">
              <img
                class="edit"
                src="../../../assets/images/mycenter/editor.png"
                @click="editWantJob(item)"
              />
              <img v-if="resumeInfo.userJobList.length>1"
                class="laji"
                src="../../../assets/images/mycenter/lajitong.png"
                @click="delWantJob(item)"
              />
            </div>
          </div>
          <div class="q-other">
            <div class="qo-bqian font14" v-if="item.skillIds">
              <span v-for="(sub, i) in item.skillIds" :key="i">{{
                sub.itemText
              }}</span>
            </div>
            <div class="qo-time">
              <img src="../../../assets/images/mycenter/qi.png" />
              <span>{{ item.startDate }}</span>
              <span class="dao">-</span>
              <img src="../../../assets/images/mycenter/zhi.png" />
              <span>{{ item.endDate ? item.endDate : "/" }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 工作经历 -->
      <div class="gonggong" v-if="!workstuts">
        <div class="tit-header font18" id="gzjl">
          <div class="p-tit">工作经历</div>
          <div class="caozuo font14" @click="addWorkExp">
            <img src="../../../assets/images/mycenter/add.png" />
            添加
          </div>
        </div>
        <div
          class=""
          style="border-left: 1px solid #f3f3f3; border-bottom: 1px solid #f3f3f3; padding-left: 5px"
          v-for="(item, index) in resumeInfo.workExpList"
          :key="index"
        >
          <div class="position">
            <div class="p-left font18">
              {{ item.companyName
              }}<span class="font14">{{ item.jobName }}</span>
              <div class="time font14">
                {{ item.startDate }}-{{ item.endDate ? item.endDate : "/" }}
              </div>
            </div>

            <div class="p-right">
              <img
                class="edit"
                @click="editWorkExp(item)"
                src="../../../assets/images/mycenter/editor.png"
              />
              <img
                class="laji"
                @click="delWorkExp(item)"
                src="../../../assets/images/mycenter/lajitong.png"
              />
            </div>
          </div>
          <div class="miaoshu">
            <div class="">{{ item.jobDescription }}</div>
          </div>
        </div>
      </div>
      <div class="qiuzhi" v-if="workstuts">
        <div class="p-tit font18" id="gzjl">
          {{ workType == 1 ? "添加" : "编辑" }}工作经历
        </div>
        <div class="formbox">
          <el-form
            :model="workExp"
            ref="workExp"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="公司名称："
              prop="companyName"
              :rules="[{ required: true, message: '请输入公司名称' }]"
            >
              <el-input
                v-model="workExp.companyName"
                placeholder="请输入公司名称"
                style="width: 260px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="职位名称："
              prop="jobName"
              :rules="[{ required: true, message: '请输入职位名称' }]"
            >
              <el-input
                v-model="workExp.jobName"
                placeholder="请输入职位名称"
                style="width: 260px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="在职时间："
              prop="endDate"
              :rules="[{ required: true, message: '在职时间不能为空' }]"
            >
              <el-date-picker
                v-model="workExp.startDate"
                :picker-options="pickerOptions"
                type="date"
                placeholder="选择日期"
                style="width: 315px; margin-right: 20px"
                @change="datePickChecker('workExpTime')"
              >
              </el-date-picker>
              <el-date-picker
                v-model="workExp.endDate"
                :picker-options="pickerOptions"
                type="date"
                placeholder="选择日期"
                style="width: 315px"
                @change="datePickChecker('workExpTime')"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="职位描述："
              prop="jobDescription"
              :rules="[{ required: true, message: '请输入职位描述' }]"
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 8, maxRows: 10 }"
                show-word-limit
                placeholder="请输入职位描述~"
                v-model="workExp.jobDescription"
                style="width: 650px"
              ></el-input>
            </el-form-item>

            <div
              style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                margin-right: 22px;
              "
            >
              <el-button class="quxb" @click="workstuts = !workstuts"
                >取消</el-button
              >
              <el-button
                class="que"
                type="primary"
                @click="submitForm('workExp')"
                >保存</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
      <!-- 教育经历 -->
      <div class="gonggong" v-if="!jiaoyustuts">
        <div class="tit-header font18" id="jyjl">
          <div class="p-tit">教育经历</div>
          <div class="caozuo font14" @click="addEducation">
            <img src="../../../assets/images/mycenter/add.png" />
            添加
          </div>
        </div>
        <div
          class=""
          style="border-left: 1px solid #f3f3f3; border-bottom: 1px solid #f3f3f3; padding-left: 5px"
          v-for="(item, index) in resumeInfo.educationList"
          :key="index"
        >
          <div class="position">
            <div class="p-left font18">
              {{ item.schoolName }} | {{ item.major }} |
              <span v-if="item.educationType"> {{ item.educationType.itemText }}</span>
              <div class="time font14">
                {{ item.startDate }}-{{ item.endDate ? item.endDate : "/" }}
              </div>
            </div>
            <div class="p-right">
              <img
                class="edit"
                @click="editEducation(item)"
                src="../../../assets/images/mycenter/editor.png"
              />
              <img
                class="laji"
                @click="delEducation(item)"
                src="../../../assets/images/mycenter/lajitong.png"
              />
            </div>
          </div>
          <div class="miaoshu">
            <div class="">{{ item.description }}</div>
          </div>
        </div>
      </div>
      <div class="qiuzhi" v-if="jiaoyustuts">
        <div class="p-tit font18" id="jyjl">
          {{ jiaoyuType == 1 ? "添加" : "编辑" }}教育经历
        </div>
        <div class="formbox">
          <el-form
            :model="education"
            ref="education"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="学校名称："
              prop="schoolName"
              :rules="[{ required: true, message: '请输入学校名称' }]"
            >
              <el-input
                v-model="education.schoolName"
                placeholder="请输入学校名称"
                style="width: 260px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="专业名称："
              prop="major"
              :rules="[{ required: true, message: '请输入专业名称' }]"
            >
              <el-input
                v-model="education.major"
                placeholder="请输入专业名称"
                style="width: 260px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="学历："
              prop="educationType"
              :rules="[{ required: true, message: '请选择学历' }]"
            >
              <el-select
                v-model="education.educationType"
                placeholder="请选择学历"
                style="width: 650px"
              >
                <el-option
                  v-for="item in educationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="在校时间："
              prop="endDate"
              :rules="[{ required: true, message: '在校时间不能为空' }]"
            >
              <el-date-picker
                v-model="education.startDate"
                :picker-options="pickerOptions"
                type="date"
                placeholder="选择日期"
                style="width: 315px; margin-right: 20px"
                @change="datePickChecker('eduExpTime')"
              >
              </el-date-picker>
              <el-date-picker
                v-model="education.endDate"
                :picker-options="pickerOptions"
                type="date"
                placeholder="选择日期"
                style="width: 315px"
                @change="datePickChecker('eduExpTime')"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="在校情况："
              prop="description"
              :rules="[{ required: true, message: '请描述在校情况' }]"
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 8, maxRows: 10 }"
                show-word-limit
                placeholder="请描述在校情况~"
                v-model="education.description"
                style="width: 650px"
              ></el-input>
            </el-form-item>

            <div
              style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                margin-right: 22px;
              "
            >
              <el-button class="quxb" @click="jiaoyustuts = !jiaoyustuts"
                >取消</el-button
              >
              <el-button
                class="que"
                type="primary"
                @click="submitForm('education')"
                >保存</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
      <div class="gonggong" v-if="!xiangmustuts">
        <div class="tit-header font18" id="xmjl">
          <div class="p-tit">项目经历</div>
          <div class="caozuo font14" @click="addProjectExp">
            <img src="../../../assets/images/mycenter/add.png" />
            添加
          </div>
        </div>
        <div
          class=""
          style="border-left: 1px solid #f3f3f3; border-bottom: 1px solid #f3f3f3; padding-left: 5px"
          v-for="(item, index) in resumeInfo.projectExpList"
          :key="index"
        >
          <div class="position">
            <div class="p-left font18">
              {{ item.projectName }}
              <div class="time font14">
                {{ item.projectStartDate }}-{{
                  item.projectEndDate ? item.projectEndDate : "/"
                }}
              </div>
            </div>
            <div class="p-right">
              <img
                class="edit"
                @click="editProjectExp(item)"
                src="../../../assets/images/mycenter/editor.png"
              />
              <img
                class="laji"
                @click="delProjectExp(item)"
                src="../../../assets/images/mycenter/lajitong.png"
              />
            </div>
          </div>
          <div class="q-other" style="margin-top: 20px">
            <div class="qo-bqian font14">
              <span v-for="(sub, i) in item.projectTechnology" :key="i">{{
                sub.itemText
              }}</span>
            </div>
          </div>
          <div class="miaoshu">
            <div class="">{{ item.projectDescription }}</div>
          </div>
        </div>
      </div>
      <div class="qiuzhi" v-if="xiangmustuts">
        <div class="p-tit font18" id="xmjl">
          {{ xiangmuType == 1 ? "添加" : "编辑" }}项目经历
        </div>
        <div class="formbox">
          <el-form
            :model="projectExp"
            ref="projectExp"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="项目名称："
              prop="projectName"
              :rules="[{ required: true, message: '请输入项目名称' }]"
            >
              <el-input
                v-model="projectExp.projectName"
                placeholder="请输入项目名称"
                style="width: 260px"
              ></el-input>
            </el-form-item>
            <el-form-item label="项目链接：" prop="projectUrl">
              <el-input
                v-model="projectExp.projectUrl"
                placeholder="请输入项目链接"
                style="width: 260px"
              ></el-input>
            </el-form-item>
            <el-form-item label="项目技能：" prop="projectTechnology">
              <el-select
                  v-model="skillType"
                  placeholder="请选择技能类型"
                  style="width: 315px; margin-right: 20px"
                  @change="onSkillTypeChanged"
              >
                <el-option
                    v-for="item in skillTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </el-select>

              <el-select
                :value="projectSkillNames.join(',')"
                placeholder="请选择项目技能"
                style="width: 315px"
                @click.native="projectSkill"
              >
                <!-- <el-option v-for="item in gzoptions" :key="item.value" :label="item.label" :value="item.value"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item
              label="项目时间："
              prop="projectEndDate"
              :rules="[{ required: true, message: '项目时间不能为空' }]"
            >
              <el-date-picker
                v-model="projectExp.projectStartDate"
                :picker-options="pickerOptions"
                type="date"
                placeholder="选择日期"
                style="width: 315px; margin-right: 20px"
                @change="datePickChecker('projectExpTime')"
              >
              </el-date-picker>
              <el-date-picker
                v-model="projectExp.projectEndDate"
                :picker-options="pickerOptions"
                type="date"
                placeholder="选择日期"
                style="width: 315px"
                @change="datePickChecker('projectExpTime')"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="项目描述："
              prop="projectDescription"
              :rules="[{ required: true, message: '请描述项目描述' }]"
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 8, maxRows: 10 }"
                show-word-limit
                placeholder="请描述项目描述~~"
                v-model="projectExp.projectDescription"
                style="width: 650px"
              ></el-input>
            </el-form-item>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                margin-right: 22px;
              "
            >
              <el-button class="quxb" @click="xiangmustuts = !xiangmustuts"
                >取消</el-button
              >
              <el-button
                class="que"
                type="primary"
                @click="submitForm('projectExp')"
                >保存</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
      <div class="gonggong" v-if="!zigestuts">
        <div class="tit-header font18" id="zgzs">
          <div class="p-tit">资格证书</div>
          <div class="caozuo font14" @click="addCert">
            <img src="../../../assets/images/mycenter/add.png" />
            添加
          </div>
        </div>
        <div
          class="position"
          v-for="(item, index) in resumeInfo.honorCertList"
          :key="index"
        >
          <div class="book font16">
            <div class="">
              {{ item.name }}
            </div>
            <div class="lookmore" v-if="item.certImage && item.shCertStatus === 1"
                @click="lookbooks(item.certImage)">
              查看证书
            </div>
            <div class="lookmore" v-if="item.shCertStatus === 0" style="color: #55a532" disabled="true">
              证书审核中
            </div>
          </div>
          <div class="p-right">
            <img
              class="edit"
              v-if="item.certImage"
              @click="editCert(item)"
              src="../../../assets/images/mycenter/editor.png"
            />
            <img
              class="laji"
              @click="delCert(item)"
              src="../../../assets/images/mycenter/lajitong.png"
            />
          </div>
        </div>
      </div>
      <div class="qiuzhi" v-if="zigestuts">
        <div class="p-tit font18" id="zgzs">
          {{ zigetype == 1 ? "添加" : "编辑" }}证书
        </div>
        <div class="formbox">
          <el-form
            :model="cert"
            ref="cert"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="证书名称："
              prop="name"
              :rules="[{ required: true, message: '请输入证书名称' }]"
            >
              <el-input
                v-model="cert.name"
                placeholder="请输入证书名称"
                style="width: 650px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="图片描述:"
              prop="certImage"
              :rules="[{ required: true, message: '请上传图片描述' }]"
            >
              <el-upload
                action="https://freemen.work/user/file/upload/"
                list-type="picture-card"
                :file-list="certImgList"
                :on-success="handleCertSuccess"
                :on-remove="handleCertRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <!-- <el-dialog :visible.sync="updialogVisible"><img width="100%" :src="bookForm.imgurl" alt="" /></el-dialog> -->
              <div class="font14" style="color: #999999">
                只能上传jpg/png格式文件，文件不能超过3M
              </div>
            </el-form-item>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                margin-right: 22px;
              "
            >
              <el-button class="quxb" @click="zigestuts = !zigestuts"
                >取消</el-button
              >
              <el-button class="que" type="primary" @click="submitForm('cert')"
                >保存</el-button
              >
            </div>
          </el-form>
        </div>
      </div>

      <div class="gonggong" id="waiyu" v-if="!waiyustuts">
        <div class="tit-header font18">
          <div class="p-tit">外语能力</div>
          <div class="caozuo font14" @click="addLanguage">
            <img src="../../../assets/images/mycenter/add.png" />
            添加
          </div>
        </div>
        <div
          class="position"
          v-for="(item, index) in resumeInfo.languageList"
          :key="index"
        >
          <div class="book font16">
            <div class="">
              {{ item.languageType.itemText }} |
              {{ item.languageLevel.itemText }}
            </div>
            <div
              class="lookmore"
              v-if="item.imageUrl"
              @click="lookbooks(item.imageUrl)"
            >
              查看证书
            </div>
          </div>
          <div class="p-right">
            <img
              class="edit"
              @click="editLanguage(item)"
              src="../../../assets/images/mycenter/editor.png"
            />
            <img
              class="laji"
              @click="delLanguage(item)"
              src="../../../assets/images/mycenter/lajitong.png"
            />
          </div>
        </div>
      </div>
      <div class="qiuzhi" v-if="waiyustuts">
        <div class="p-tit font18" id="zgzs">
          {{ waiyuType == 1 ? "添加" : "编辑" }}外语能力
        </div>
        <div class="formbox">
          <el-form
            :model="language"
            ref="language"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="语言能力："
              prop="languageType"
              :rules="[{ required: true, message: '选择语种' }]"
            >
              <el-select
                v-model="language.languageType"
                placeholder="选择语种"
                style="width: 260px"
                @change="onLangChange"
              >
                <el-option
                  v-for="item in languageTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="语言等级："
              prop="languageLevel"
              :rules="[{ required: true, message: '选择语言等级' }]"
            >
              <el-select
                v-model="language.languageLevel"
                placeholder="选择语言等级"
                style="width: 260px"
              >
                <el-option
                  v-for="item in languageLevelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="上传证书图片："
              prop="imageUrl"
              :rules="[{ required: true, message: '请上传证书图片' }]"
            >
              <el-upload
                action="https://freemen.work/user/file/upload/"
                list-type="picture-card"
                :file-list="languageImgList"
                :on-success="handleLanguageSuccess"
                :on-remove="handleLanguageRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <!-- <div class="font14" style="color: #999999;">
								只能上传jpg/png格式文件，文件不能超过3M
							</div> -->
            </el-form-item>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                margin-right: 22px;
              "
            >
              <el-button class="quxb" @click="waiyustuts = !waiyustuts"
                >取消</el-button
              >
              <el-button
                class="que"
                type="primary"
                @click="submitForm('language')"
                >保存</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
      <div class="gonggong" v-if="!shipingstuts">
        <div class="tit-header font18" id="spjl">
          <div class="p-tit">视频简历</div>
          <div class="caozuo font14">
            <img
                style="margin-right: 30px"
                @click="shipingstuts = !shipingstuts"
                src="../../../assets/images/mycenter/editor.png"
            />
            <!-- <img src="../../../assets/images/mycenter/add.png" >
						添加 -->
            <img
              class="laji"
              v-if="video.resUrl"
              @click="delVideo"
              src="../../../assets/images/mycenter/lajitong.png"
            />
          </div>
        </div>
        <div class="book font16" v-if="resumeInfo.userCv">
          <div class="">
            {{ resumeInfo.userCv.cvName }}
          </div>
          <div class="position"></div>
        </div>
        <div class="video" v-if="resumeInfo.userCv && resumeInfo.userCv.resUrl">
          <div class="imgshowmain">
            <video
              v-bind:src="
                'https://freemen.work/user/file/view/' +
                resumeInfo.userCv.resUrl
              "
              class="avatar video-avatar"
              controls="controls"
            >
              您的浏览器不支持视频播放
            </video>
            <div class="videoBox">
              <img
                class="bofang"
                src="../../../assets/images/common/bofang-img.png"
                @click="videoshow(resumeInfo.userCv)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="qiuzhi" v-if="shipingstuts">
        <div class="p-tit font18" id="spjl">编辑视频简历</div>
        <div class="formbox">
          <el-form
            :model="video"
            ref="video"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="标题："
              prop="cvName"
              :rules="[{ required: true, message: '请输入视频标题' }]"
            >
              <el-input
                v-model="video.cvName"
                placeholder="请输入视频标题"
                style="width: 650px"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="视频简历:"
              prop="resUrl"
              :rules="[{ required: true, message: '请上传视频简历' }]"
            >
              <div class="imgshowmain" v-if="video.resUrl">
                <video
                  v-bind:src="
                    'https://freemen.work/user/file/view/' + video.resUrl
                  "
                  class="avatar video-avatar"
                  controls="controls"
                >
                  您的浏览器不支持视频播放
                </video>
                <div class="videoBox">
                  <img
                    class="el-icon-error"
                    src="../../../assets/images/common/sahnchu.png"
                    @click="delVideo"
                  />
                </div>
              </div>
              <el-upload
                v-else
                action="https://freemen.work/user/file/upload/"
                list-type="picture-card"
                :on-success="handleVideoSuccess"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item
              label="介绍："
              prop="cvDescription"
              :rules="[{ required: true, message: '请描述简介~' }]"
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 8, maxRows: 10 }"
                show-word-limit
                placeholder="请描述简介~"
                v-model="video.cvDescription"
                style="width: 650px"
              ></el-input>
            </el-form-item>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                margin-right: 22px;
              "
            >
              <el-button class="quxb" @click="shipingstuts = !shipingstuts"
                >取消</el-button
              >
              <el-button class="que" type="primary" @click="submitForm('video')"
                >保存</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
      <div class="uploaditem" v-if="isFromUpload">
        <div class="item">
          <span>有新的信息未保存！</span>
          <el-button class="que" type="primary" @click="optionUpload(true)">保存</el-button>
          <el-button class="quxb" @click="optionUpload(false)">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "../../../common/bus.js";
import { cityDataes } from "../../../common/city.js";
import { options } from "../../../common/options.js";
// import { cityDataes } from "../../common/addAddress.js";

export default {
  filters: {
    numFilter(value) {
      let transformVal = Number(value).toFixed(2)
      let realVal = transformVal.substring(0, transformVal.length - 1)

      return Number(realVal)
    }
  },
  data() {
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error("邮箱不能为空"));
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的邮箱格式"));
        }
      }, 100);
    };
    return {
      // 日期限制
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7 // 加- 8.64e7则表示包当天
        },
      },
      emailrules: {
        email: [
          { validator: checkEmail, required: true, trigger: "blur" },
          { required: true, message: "请输入电子邮箱" },
        ],
      },
      resumeInfo: {},
      isFromUpload: false,  // 是否有来自简历识别的信息入例
      isUploadSaved: {
        eduSaved: false,
        projectSaved: false,
        expSaved: false,
        certSaved: false,
      },
      uploadResumeInfo: {},
      user: {
        avatar: "",
        birthday: "",
        displayName: "",
        sex: "",
        email: "",
        wxNumber: "",
      },
      phone: "",
      gerenstuts: false,
      userJob: {
        id: "",
        jobName: "",
        startDate: "",
        endDate: "",
        city: "",
        city2:"",
        skillIds: "",
        expYear: "",
        salaryLow: "",
        salaryHigh: "",
      },
      // 弹窗展开后，选中显示的技能名称列表
      skillNames: [],
      skillIds: [],
      skillList: [],
      skillTagList: [
        {skillNames: "", skillIds: ""},
        {skillNames:""},
        {skillIds:""}
      ],
      projectExp: {
        id: "",
        projectName: "",
        projectTechnology: "",
        projectStartDate: "",
        projectEndDate: "",
        projectDescription: "",
        projectUrl: "",
      },
      projectSkillNames: [],
      projectSkillList: [],
      projectSkillIds: [],
      xiangmustuts: false,
      cityCatch: [],
      cityOptions: [],
      expOptions: options.expHighOptions,
      gzLowOptions: options.gzLowOptions,
      gzHighOptions: options.gzHighOptions,
      skillType: 0,
      skillTypes: options.skillTypes,
      zhiweistuts: false,
      workExp: {
        id: "",
        companyName: "",
        jobName: "",
        startDate: "",
        endDate: "",
        jobDescription: "",
      },
      workstuts: false,
      education: {
        id: "",
        schoolName: "",
        major: "",
        educationType: "",
        startDate: "",
        endDate: "",
        description: "",
      },
      educationOptions: [],
      jiaoyustuts: false,
      cert: {
        id: "",
        name: "",
        certImage: "",
        shCertImage: "",
        shCertStatus: 1,
      },
      certImgList: [],
      zigestuts: false,
      language: {
        id: "",
        languageType: "",
        languageLevel: "",
        imageUrl: "",
      },
      languageType: {},
      languageTypeOptions: [],
      languageImgList: [],
      waiyustuts: false,
      video: {
        cvDescription: "",
        cvName: "",
        resUrl: "",
      },
      shipingstuts: false,
      type: 1,
      workType: 1,
      jiaoyuType: 1,
      xiangmuType: 1,
      zigetype: 1,
      waiyuType: 1,
      options: [],
    };
  },
  computed: {
    languageLevelOptions() {
      let arr = [];
      if (this.language.languageType) {
        this.languageType.map((item) => {
          if (this.language.languageType == item.id) {
            item.list.map((sub) => {
              let obj = {
                value: sub.id,
                label: sub.name,
              };
              arr.push(obj);
            });
          }
        });
      }
      return arr;
    },
  },
  created() {
    // console.log("$information-preview page ------ ### created!");
    let data = cityDataes.cityData;
    let city = [];
    data.forEach((p) => {
      let tmp = {
        value: p.label,
        label: p.label,
        children: [],
      };
      p.children.forEach((c) => {
        tmp.children.push({
          value: c.label,
          label: c.label,
        });
      });
      city.push(tmp);
    });
    this.options = city;

    if (sessionStorage.getItem("uploadResume")) {
      let uploadResume = JSON.parse(
          sessionStorage.getItem("uploadResume")
      );
      // console.log("$upload resume success, on preview created,  ----- parsed uploadResume info = ", uploadResume);
      this.uploadResumeInfo = uploadResume.resumeInfo;
      sessionStorage.removeItem("uploadResume");
      this.isFromUpload = true;
      this.isUploadSaved = {
        eduSaved: false,
        projectSaved: false,
        expSaved: false,
        certSaved: false,
      }
    }

    this.getResumeDetail(this.isFromUpload);
    this.getEducationList();
    this.getLanguageType();
    city.map((item) => {
      let obj = {
        label: item.name,
        value: item.name,
      };
      this.cityOptions.push(obj);
    });
  },
  mounted() {
    window.addEventListener("setItemEvent", function (e) {
      // reqHeaders.token = e.newValue
      if (e.key == "yulanname") {
        if (document.getElementById(e.newValue)) {
          document.getElementById(e.newValue).scrollIntoView();
        }
      }
    });
    let namees = localStorage.getItem("yulanname");
    // console.log("取消回到", namees);
    if (namees) {
      if (document.getElementById(namees)) {
        document.getElementById(namees).scrollIntoView();
      }
    }
  },
  methods: {
    datePickChecker(type) {  // 日期入例检测，结束时间不能晚于开始时间
      var start;
      var end;
      let warnFlag = false;
      if(type === 'workExpTime'){
        start = (new Date(this.workExp.startDate) * 1000) / 1000;
        end = (new Date(this.workExp.endDate) * 1000) / 1000;
        if (start && end && start > end) {
          this.workExp.endDate = this.workExp.startDate;
          warnFlag = true;
        }
      }
      if(type === 'eduExpTime'){
        start = (new Date(this.education.startDate) * 1000) / 1000;
        end = (new Date(this.education.endDate) * 1000) / 1000;
        if (start && end && start > end) {
          this.education.endDate = this.education.startDate;
          warnFlag = true;
        }
      }
      if(type === 'projectExpTime'){
        start = (new Date(this.projectExp.projectStartDate) * 1000) / 1000;
        end = (new Date(this.projectExp.projectEndDate) * 1000) / 1000;
        if (start && end && start > end) {
          this.projectExp.projectEndDate = this.projectExp.projectStartDate;
          warnFlag = true;
        }
      }
      if (warnFlag) {
        this.$message({
          message: "结束时间小于开始时间！",
          type: "warning",
        });
      }
    },
    dateStart() {
      if (this.userJob.endDate && Date.parse(this.userJob.startDate) > Date.parse(this.userJob.endDate)) {
        this.userJob.startDate = this.userJob.endDate
      }
    },
    dateEnd() {
      if (this.userJob.startDate && Date.parse(this.userJob.startDate) > Date.parse(this.userJob.endDate)) {
        this.userJob.endDate = this.userJob.startDate
      }
    },
    citySelector(value) {
      //城市选择
      this.userJob.city = value[1];
      this.cityCatch[0] = value[1];
      // console.log("citySelector", value);

      // this.postruleForm.cityCodeFull = value.toString();
      //   this.options.map((item) => {
      //     if (item.value == value[0]) {
      //       item.children.map((sub) => {
      //         if (sub.value == value[1]) {
      //         }
      //       });
      //     }
      //   });
      // console.log(this.ruleForm.cityCodeFull);
      // console.log(this.ruleForm.cityCode);
    },
    city2Selector(value) {
      //城市选择
      this.userJob.city2 = value[1];
      this.cityCatch[1] = value[1];
      // console.log("city2Selector", value);
    },
    onLangChange() {
      this.language.languageLevel = null;
    },
    getResumeDetail(toAddUpload) {  // 获取简历信息 toAddUpload-添加上传、识别的简历信息 uploadResumeInfo
      // console.log("加载简历信息，", toAddUpload);
      this.$api.viewResume("get").then((res) => {
        this.resumeInfo = res.data;
        // console.log("$preview ----  @getResumeDetail # uploadResumeInfo = ", this.uploadResumeInfo);
        // console.log("$preview ----  @getResumeDetail # isUploadSaved = ", this.isUploadSaved);
        if(toAddUpload){  /// 将简历识别所得信息入例，目前简历识别入例消息仅包括：教育经历、工作经历、项目经历和证书列表
          if(this.uploadResumeInfo.educationList && this.uploadResumeInfo.educationList.length > 0 && !this.isUploadSaved.eduSaved){
            this.uploadResumeInfo.educationList.map( (edu) =>{
              this.resumeInfo.educationList.push(edu);
            })
          }
          if(this.uploadResumeInfo.projectExpList && this.uploadResumeInfo.projectExpList.length > 0 && !this.isUploadSaved.projectSaved){
            this.uploadResumeInfo.projectExpList.map( (project) =>{
              this.resumeInfo.projectExpList.push(project);
            })
          }
          if(this.uploadResumeInfo.workExpList && this.uploadResumeInfo.workExpList.length > 0 && !this.isUploadSaved.expSaved){
            this.uploadResumeInfo.workExpList.map( (exp) =>{
              this.resumeInfo.workExpList.push(exp);
            })
          }
          if(this.uploadResumeInfo.honorCertList && this.uploadResumeInfo.honorCertList.length > 0 && !this.isUploadSaved.certSaved){
            this.uploadResumeInfo.honorCertList.map( (cert) =>{
              this.resumeInfo.honorCertList.push(cert);
            })
          }
        }

        for (let k in this.user) {
          this.user[k] = res.data.user[k];
        }
        for (let k in this.video) {
          this.video[k] = res.data.userCv[k];
        }
        this.phone = res.data.user.phone;
        if(res.data.user){
          window.sessionStorage.setItem("userInfo", JSON.stringify(res.data.user));
        }
        // console.log("$preview ----  @getResumeDetail # resumeInfo = ", this.resumeInfo);
        // this.getUserInfo();
      });
    },
    // getUserInfo() {
    //   this.$api.getUserInfo("get").then((res) => {
    //     this.userInfo = res.data.user;
    //     window.sessionStorage.setItem("userInfo", JSON.stringify(res.data.user));
    //   });
    // },
    getEducationList() {
      //获取所有学历
      this.$api.getdict("get", { code: "education_type" }).then((res) => {
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          this.educationOptions.push(obj);
        });
      });
    },
    getLanguageType() {
      //获取所有语言
      this.$api.getdict("get", { code: "language_type" }).then((res) => {
        this.languageType = res.data;
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          this.languageTypeOptions.push(obj);
        });
      });
    },
    bianji(name) {
      let data = {
        num: 1,
        name: name,
      };
      this.$emit("changenum", data);
    },
    // 清理原有技能列表中，选中类型对应的技能标签 skillType: 技能类型; targetList：需要清理的技能列表, jobWant-求职技能，project-项目技能
    clearSkillItemByType(skillType, targetList){
      let tempNames = [];
      let tempIds = [];
      let tempList = [];
      let codeStr = skillType === 1 ? "chip_skill" : skillType === 2 ? "non_tech_skill" : "skill";
      let mapList = targetList === "jobWant" ? this.skillList : this.projectSkillList;
      mapList.map((skillItem) => {
        if (skillItem.code !== codeStr) {
          if (this.skillNames.indexOf(skillItem.label) !== -1) {
            tempNames.push(skillItem.label);
          }
          if (this.skillIds.indexOf(skillItem.value) !== -1) {
            tempIds.push(skillItem.value);
          }
          tempList.push(skillItem)
        }
      });
      if (targetList === "jobWant") {
        this.skillNames = tempNames;
        this.skillIds = tempIds;
        this.skillList = tempList;
      } else if (targetList === "project"){
        this.projectSkillNames = tempNames;
        this.projectSkillIds = tempIds;
        this.projectSkillList = tempList;
      }
    },
    onSkillTypeChanged(typeId){
      this.skillType = typeId;
    },
    choosejineg() {
      //选择技能
      let event  = this.skillType === 1 ? "chipSkillStatus" : this.skillType === 2 ? "nonTechStatus" : "jinengstuts";
      // console.log("choose skill list, value = ", this.skillIds);
      // Bus.$emit("jinengstuts", {
       Bus.$emit(event, {
         show: true,
         model: 0,
         skillIds: this.skillIds,
        callback: (arr) => {
          // this.skillNames = [];
          // this.skillIds = [];
          this.clearSkillItemByType(this.skillType, "jobWant");
          // console.log("choose.callback skill list, arr = ", arr);
          arr.map((item) => {
            if (this.skillIds.indexOf(item.value) == -1) {
              let skillItem = {
                value: item.value,
                label: item.label,
                code: item.code,
              };
              this.skillList.push(skillItem);
              this.skillNames.push(item.label);
              this.skillIds.push(item.value);
            }
          });

          this.userJob.skillIds = this.skillIds.join(",");
        },
      });
    },
    chooselang() {
      Bus.$emit("langagestuts", true);
    },
    videoshow(data) {
      //播放视频
      Bus.$emit("videodstuts", {
        show: true,
        data: data,
      });
    },
    lookbooks(data) {
      //查看证书
      Bus.$emit("lookbookstuts", {
        show: true,
        urls: data,
      });
    },
    addtouxiang() {
      //编辑头像
      Bus.$emit("modifyavatarstuts", {
        show: true,
        callback: (url) => {
          this.user.avatar = url;
          this.$api.updateUserInfo("post", this.user).then(() => {
            this.getResumeDetail(this.isFromUpload);
            this.$message({
              message: "头像修改成功",
              type: "success",
            });
          });
        },
      });
    },
    addWantJob() {
      if (this.resumeInfo.userJobList.length >= 3) {
        this.$alert("最多只能添加3条求职意向", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      //添加求职意向
      for (let k in this.userJob) {
        this.userJob[k] = "";
      }
      this.skillIds = [];
      this.skillNames = [];
      this.zhiweistuts = true;
      this.type = 1;
    },
    editWantJob(data) {
      // console.log("编辑求职意向 -------- data = ", data);
      //编辑求职意向
      this.skillIds = [];
      this.skillNames = [];
      if(data.skillIds && data.skillIds.length > 0){
        data.skillIds.map((item) => {
          this.skillIds.push(item.itemValue);
          this.skillNames.push(item.itemText);
        });
      }
      this.userJob.skillIds = this.skillIds.join(",");
      for (let k in this.userJob) {
        if (k != "skillIds") {
          this.userJob[k] = data[k];
        }
      }
      this.cityCatch = [];
      if(this.userJob.city !== null && this.userJob.city !== ''){
        let tempCity = this.userJob.city;
        let flag = "";
        if(tempCity.endsWith("市")) flag = "市";
        if(tempCity.endsWith("城区")) flag = "城区";
        if(tempCity.endsWith("盟")) flag = "盟";
        if(tempCity.endsWith("自治州")) flag = "自治州";
        if(tempCity.endsWith("地区")) flag = "地区";
        if(tempCity.endsWith("林区")) flag = "林区";
        if(tempCity.endsWith("县")) flag = "县";
        if(tempCity.endsWith("自治县")) flag = "自治县";
        if(tempCity.endsWith("郊县")) flag = "郊县";
        if(tempCity === "台湾省") flag = "台湾省";
        if(tempCity.length > 0 && flag === ''){
          let temp = this.userJob.city + "市";
          this.cityCatch.push(temp);
        } else {
          this.cityCatch.push(this.userJob.city);
        }
      }
      if(this.userJob.city2 !== null && this.userJob.city2 !== ''){
        let tempCity = this.userJob.city2;
        let flag = "";
        if(tempCity.endsWith("市")) flag = "市";
        if(tempCity.endsWith("城区")) flag = "城区";
        if(tempCity.endsWith("盟")) flag = "盟";
        if(tempCity.endsWith("自治州")) flag = "自治州";
        if(tempCity.endsWith("地区")) flag = "地区";
        if(tempCity.endsWith("林区")) flag = "林区";
        if(tempCity.endsWith("县")) flag = "县";
        if(tempCity.endsWith("自治县")) flag = "自治县";
        if(tempCity.endsWith("郊县")) flag = "郊县";
        if(tempCity === "台湾省") flag = "台湾省";
        if(tempCity.length > 0 && flag === ''){
          let temp = this.userJob.city2 + "市";
          this.cityCatch.push(temp);
        } else {
          this.cityCatch.push(this.userJob.city2);
        }
      } else {
        this.cityCatch.push("");
      }
      // console.log("Edit JobWant, info ### this.userJob = ", this.userJob, " ----- tempCatch = ", this.cityCatch);
      this.zhiweistuts = true;
      this.type = 2;
    },
    delWantJob(data) {
      //删除求职意向
      Bus.$emit("deletestuts", {
        show: true,
        callback: () => {

          if(data.id){
            this.$api.delWantJob("post", { id: data.id }).then(() => {
              this.getResumeDetail(this.isFromUpload);
              this.$message({
                message: "求职意向删除成功",
                type: "success",
              });
            });
          } else {

            if (this.uploadResumeInfo.workExpList.length > 1) {
              let eduList = this.uploadResumeInfo.workExpList;
              this.uploadResumeInfo.workExpList = eduList.map((edu, index) => {
                if (edu.schoolName === data.schoolName && edu.major === data.map) {
                  eduList.splice(index, 1);
                }
              });
            } else {
              this.isUploadSaved.expSaved = true;
              this.uploadResumeInfo.workExpList = [];
            }

            this.getResumeDetail(this.isFromUpload);
            this.$message({
              message: "求职意向删除成功",
              type: "success",
            });

          }


        },
      });
    },
    addWorkExp() {
      //添加工作经历
      for (let k in this.workExp) {
        this.workExp[k] = "";
      }
      this.workstuts = true;
      this.workType = 1;
    },
    editWorkExp(data) {
      //编辑工作经历
      for (let k in this.workExp) {
        this.workExp[k] = data[k];
      }
      this.workstuts = true;
      this.workType = 2;
    },
    delWorkExp(data) {
      //删除工作经历
      Bus.$emit("deletestuts", {
        show: true,
        callback: () => {
          this.$api.delWorkExp("post", { id: data.id }).then(() => {
            this.getResumeDetail(this.isFromUpload);
            this.$message({
              message: "工作经历删除成功",
              type: "success",
            });
          });
        },
      });
    },
    addEducation() {
      //添加教育经历
      for (let k in this.education) {
        this.education[k] = "";
      }
      this.jiaoyustuts = true;
      this.jiaoyuType = 1;
    },
    editEducation(data) {
      for (let k in this.education) {
        if (k != "educationType") {
          this.education[k] = data[k];
        }
      }
      if(data.educationType){
        this.education.educationType = Number(data.educationType.itemValue);
      }
      this.jiaoyustuts = true;
      this.jiaoyuType = 2;
    },
    delEducation(data) {
      Bus.$emit("deletestuts", {
        show: true,
        callback: () => {
          if (data.id) {  // 简历识别所得信息无ID
            this.$api.delEducation("post", {id: data.id}).then(() => {
              this.getResumeDetail(this.isFromUpload);
              this.$message({
                message: "教育经历删除成功",
                type: "success",
              });
            });
          } else {

            if (this.uploadResumeInfo.educationList.length > 1) {
              let eduList = this.uploadResumeInfo.educationList;
              this.uploadResumeInfo.educationList = eduList.map((edu, index) => {
                if (edu.schoolName === data.schoolName && edu.major === data.map) {
                  eduList.splice(index, 1);
                }
              });
            } else {
              this.isUploadSaved.eduSaved = true;
              this.uploadResumeInfo.educationList = [];
            }

            this.getResumeDetail(this.isFromUpload);
            this.$message({
              message: "教育经历删除成功",
              type: "success",
            });
          }
        },
      });
    },
    projectSkill() {
      //项目技能
      let event  = this.skillType === 1 ? "chipSkillStatus" : this.skillType === 2 ? "nonTechStatus" : "jinengstuts";
      // console.log("choose project's skill list, value = ", this.projectSkillIds);
      Bus.$emit(event, {
        show: true,
        model: 0,
        skillIds: this.projectSkillIds,
        callback: (arr) => {
          // this.projectSkillNames = [];
          // this.projectSkillIds = [];
          this.clearSkillItemByType(this.skillType, "project");
          // console.log("choose.callback ---- project skill list, arr = ", arr);
          arr.map((item) => {
            if (this.projectSkillIds.indexOf(item.value) == -1) {
              let skillItem = {
                value: item.value,
                label: item.label,
                code: item.code,
              };
              this.projectSkillList.push(skillItem);
              this.projectSkillNames.push(item.label);
              this.projectSkillIds.push(item.value);
            }
          });
          this.projectExp.projectTechnology = this.projectSkillIds.join(",");
        },
      });
    },
    addProjectExp() {
      //添加项目经历
      for (let k in this.projectExp) {
        this.projectExp[k] = "";
      }
      this.projectSkillIds = [];
      this.projectSkillNames = [];
      this.xiangmustuts = true;
      this.xiangmuType = 1;
    },
    editProjectExp(data) {
      //编辑项目经历
      this.projectSkillIds = [];
      this.projectSkillNames = [];
      if(data.projectTechnology){
        data.projectTechnology.map((item) => {
          this.projectSkillIds.push(item.itemValue);
          this.projectSkillNames.push(item.itemText);
        });
      }
      this.projectExp.projectSkillNames = this.projectSkillIds.join(",");
      for (let k in this.projectExp) {
        if (k != "projectTechnology") {
          this.projectExp[k] = data[k];
        }
      }
      this.xiangmustuts = true;
      this.xiangmuType = 2;
    },
    delProjectExp(data) {
      //删除项目经历
      Bus.$emit("deletestuts", {
        show: true,
        callback: () => {
          if(data.id){
            this.$api.delProjectExp("post", { id: data.id }).then(() => {
              this.getResumeDetail(this.isFromUpload);
              this.$message({
                message: "项目经历删除成功",
                type: "success",
              });
            });
          } else {
            if (this.uploadResumeInfo.projectExpList.length > 1) {
              let projectList = this.uploadResumeInfo.projectExpList;
              this.uploadResumeInfo.projectExpList = projectList.map((edu, index)=>{
                if(edu.schoolName === data.schoolName && edu.major === data.map){
                  projectList.splice(index, 1);
                }
              });
            } else {
              this.isUploadSaved.projectSaved = true;
              this.uploadResumeInfo.projectExpList = [];
            }
            this.getResumeDetail(this.isFromUpload);
            this.$message({
              message: "项目经历删除成功",
              type: "success",
            });
          }
        },
      });
    },
    handleCertSuccess(response, file, fileList) {
      //证书图片上传成功
      // console.log(response, file, fileList);
      this.certImgList.push({
        url: "https://freemen.work/user/file/view/" + response.data,
        name: response.data,
      });
      let certArr = [];
      this.certImgList.map((item) => {
        certArr.push(item.name);
      });
      this.cert.certImage = certArr.join(",");
    },
    handleCertRemove(file, fileList) {
      //证书图片删除成功
      // console.log(file, fileList);
      let remIndex = "";
      this.certImgList.map((item, index) => {
        if (item.name == file.name) {
          remIndex = index;
        }
      });
      this.certImgList.splice(remIndex, 1);
      let certArr = [];
      this.certImgList.map((item) => {
        certArr.push(item.name);
      });
      this.cert.certImage = certArr.join(",");
    },
    addCert() {
      //添加证书
      for (let k in this.cert) {
        this.cert[k] = "";
      }
      this.certImgList = [];
      this.zigestuts = true;
      this.zigetype = 1;
    },
    editCert(data) {
      //编辑证书
      this.certImgList = [];
      for (let k in this.cert) {
        this.cert[k] = data[k];
      }
      if(this.cert.certImage && this.cert.certImage !== ""){
        let arr;
        if(!this.cert.shCertStatus || this.cert.shCertStatus === 1){  // 审核通过状态下编辑原证书图
          arr = this.cert.certImage.split(",");
        } else {    // 审核中状态下编辑待审核证书图
          arr = this.cert.shCertImage.split(",");
        }
        arr.map((item) => {
          let obj = {
            url: "https://freemen.work/user/file/view/" + item,
            name: item,
          };
          this.certImgList.push(obj);
        });
      }
      let certArr = [];
      this.certImgList.map((item) => {
        certArr.push(item.name);
      });
      this.cert.certImage = certArr.join(",");
      this.zigestuts = true;
      this.zigetype = 2;
    },
    delCert(data) {
      //删除证书
      Bus.$emit("deletestuts", {
        show: true,
        callback: () => {
          if(data.id){
            this.$api.delCert("post", { id: data.id }).then(() => {
              this.getResumeDetail(this.isFromUpload);
              this.$message({
                message: "证书删除成功",
                type: "success",
              });
            });
          } else {

            if (this.uploadResumeInfo.honorCertList.length > 1) {
              let certList = this.uploadResumeInfo.honorCertList;
              this.uploadResumeInfo.honorCertList = certList.map((edu, index)=>{
                if(edu.schoolName === data.schoolName && edu.major === data.map){
                  certList.splice(index, 1);
                }
              });
            } else {
              this.isUploadSaved.certSaved = true;
              this.uploadResumeInfo.honorCertList = [];
            }

            this.getResumeDetail(this.isFromUpload);
            this.$message({
              message: "证书删除成功",
              type: "success",
            });
          }
        },
      });
    },
    handleLanguageSuccess(response, file, fileList) {
      // console.log(response, file, fileList);
      this.languageImgList.push({
        url: "https://freemen.work/user/file/view/" + response.data,
        name: response.data,
      });
      let languageArr = [];
      this.languageImgList.map((item) => {
        languageArr.push(item.name);
      });
      this.language.imageUrl = languageArr.join(",");
    },
    handleLanguageRemove(file, fileList) {
      // console.log(file, fileList);
      let remIndex = "";
      this.languageImgList.map((item, index) => {
        if (item.name == file.name) {
          remIndex = index;
        }
      });
      this.languageImgList.splice(remIndex, 1);
      // console.log(this.languageImgList);
      let languageArr = [];
      this.languageImgList.map((item) => {
        languageArr.push(item.name);
      });
      this.language.imageUrl = languageArr.join(",");
    },
    addLanguage() {
      //添加外语能力
      for (let k in this.language) {
        this.language[k] = "";
      }
      this.languageImgList = [];
      this.waiyustuts = true;
      this.waiyuType = 1;
    },
    editLanguage(data) {
      //编辑外语能力
      this.languageImgList = [];
      this.language.id = data.id;
      this.language.languageType = Number(data.languageType.itemValue);
      this.language.languageLevel = Number(data.languageLevel.itemValue);
      this.language.imageUrl = data.imageUrl;
      let arr = this.language.imageUrl.split(",");
      arr.map((item) => {
        let obj = {
          url: "https://freemen.work/user/file/view/" + item,
          name: item,
        };
        this.languageImgList.push(obj);
      });
      let languageArr = [];
      this.languageImgList.map((item) => {
        languageArr.push(item.name);
      });
      this.language.imageUrl = languageArr.join(",");
      this.waiyustuts = true;
      this.waiyuType = 2;
    },
    delLanguage(data) {
      //删除外语能力
      Bus.$emit("deletestuts", {
        show: true,
        callback: () => {
          this.$api.delLanguage("post", { id: data.id }).then(() => {
            this.getResumeDetail(this.isFromUpload);
            this.$message({
              message: "外语能力删除成功",
              type: "success",
            });
          });
        },
      });
    },
    handleVideoSuccess(response, file, fileList) {
      //视频简历上传成功
      // console.log(response, file, fileList);
      this.video.resUrl = response.data;
    },
    delVideo() {
      //视频简历删除
      // console.log(111);
      this.video.resUrl = "";
      this.video.cvDescription = "";
      this.video.cvName = "";
      this.$api.saveVideo("post", this.video).then(() => {
        this.getResumeDetail(this.isFromUpload);
        this.$message({
          message: "视频简历删除成功",
          type: "success",
        });
      });
    },
    // 保存/取消 上传简历信息
    optionUpload(save){
      let flag = "";
      if(save){
        let resumeConfig = this.resumeInfo;
        // 简历识别入例信息完整检测
        if (resumeConfig.educationList && resumeConfig.educationList.length > 0) {
          let eduList = resumeConfig.educationList;
          for (var i = 0; i < eduList.length; i++) {
            let edu = eduList[i];
            if (edu.schoolName === null) {
              flag = "请确认教育经历，学校名称不能为空！";
              break;
            }
            if (edu.major === null) {
              flag = "请确认教育经历，就读专业不能为空！";
              break;
            }
            if (edu.educationType === null) {
              flag = "请确认教育经历，对应学历不能为空！";
              break;
            } else { //
              if (edu.educationType.itemValue) {
                let eduType = edu.educationType.itemValue;
                resumeConfig.educationList[i].educationType = eduType;
              }
            }
            if (edu.endDate === null || edu.startDate === null) {
              flag = "请确认教育经历，就读时间不能为空！";
              break;
            }
          }
        }
        if (flag !== "") {
          this.$message({
            message: flag,
            type: "warning",
          });
          return;
        }
        if (resumeConfig.projectExpList && resumeConfig.projectExpList.length > 0) {
          let projectList = resumeConfig.projectExpList;
          for (var i = 0; i < projectList.length; i++) {
            let project = projectList[i];

            if (!project.projectName){
              flag = "请确认项目经历，项目名称不能为空！";
              break;
            }
            if (project.projectStartDate === null || project.projectEndDate === null) {
              flag = "请确认项目经历，项目时间不能为空！";
              break;
            }
            if (!project.projectDescription){
              flag = "请确认项目经历，项目简述不能为空！";
              break;
            }
            // 项目技能列表 字典ID处理
            if (project.projectTechnology && project.projectTechnology.length > 0){
              let expSkillIdList = [];
              project.projectTechnology.map((item) => {
                if (item.itemValue && expSkillIdList.indexOf(item.itemValue) === -1) {
                  expSkillIdList.push(item.itemValue);
                }
              });
              if (expSkillIdList.length > 0) {
                resumeConfig.projectExpList[i].projectTechnology = expSkillIdList.join(",");
              }
            }
          }
        }
        if (flag !== "") {
          this.$message({message: flag, type: "warning",});
          return;
        }
        if (resumeConfig.workExpList && resumeConfig.workExpList.length > 0) {
          let expList = resumeConfig.workExpList;
          for (var i = 0; i < expList.length; i++) {
            let exp = expList[i];
            if (!exp.jobName) {
              flag = "请确认工作经历，工作名称不能为空！";
              break;
            }
            if (!exp.companyName) {
              flag = "请确认工作经历，公司名称不能为空！";
              break;
            }
            if (exp.startDate === null || exp.endDate === null) {
              flag = "请确认工作经历，工作时间不能为空！";
              break;
            }
          }
        }
        if (flag !== "") {
          this.$message({message: flag, type: "warning",});
          return;
        }

        if(resumeConfig.honorCertList && resumeConfig.honorCertList.length > 0){
          let certList = resumeConfig.honorCertList;
          for (var i = 0; i < certList.length; i++){
            let cert = certList[i];
            if (!cert.name){
              flag = "请确认资格证书，证书名称不能为空！";
              break;
            }
          }
        }
        if (flag !== "") {
          this.$message({message: flag, type: "warning",});
          return;
        }
        // 求职技能列表字典ID处理
        if (resumeConfig.userJobList && resumeConfig.userJobList.length > 0) {
          let userJobs = resumeConfig.userJobList;
          for (var i = 0; i < userJobs.length; i++) {
            let job = userJobs[i];

            if (job.skillIds && job.skillIds.length > 0) {
              let skillIdList = [];
              job.skillIds.map((item) => {
                if (item.itemValue && skillIdList.indexOf(item.itemValue) === -1) {
                  skillIdList.push(item.itemValue);
                }
              });
              if (skillIdList.length > 0) {
                resumeConfig.userJobList[i].skillIds = skillIdList.join(",");
              }
            }
          }
        }
        // 外语种类、等级 字典ID处理
        if (resumeConfig.languageList && resumeConfig.languageList.length > 0) {
          let languages = resumeConfig.languageList;
          for (var i = 0; i < languages.length; i++) {
            let language = languages[i];

            if (language.languageType && language.languageType.itemValue) {
              resumeConfig.languageList[i].languageType = language.languageType.itemValue;
            }

            if (language.languageLevel && language.languageLevel.itemValue) {
              resumeConfig.languageList[i].languageLevel = language.languageLevel.itemValue;
            }
          }
        }

        // 通过完整性检测后上次保存
        this.$api.saveResume("post", resumeConfig).then((res) => {
          // console.log("$Preview --- @saveResume ### res = ", res);
          if (res.code == 10200) {
            this.$message({
              message: "完成",
              type: "success",
            });
          }
        })
      }

      // 更新本地数据
      this.isFromUpload = false;
      this.isUploadSaved = {
        eduSaved: true,
        projectSaved: true,
        expSaved: true,
        certSaved: true,
      };
      this.uploadResumeInfo = {};
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName == "user") {
            let config = JSON.parse(JSON.stringify(this.user));
            let time = new Date(config.birthday);
            let y = time.getFullYear();
            let m = time.getMonth() + 1;
            let d = time.getDate();
            config.birthday = y + "-" + m + "-" + d;
            this.$api.updateUserInfo("post", config).then(() => {
              this.getResumeDetail(this.isFromUpload);
              this.$message({
                message: "基本信息修改成功",
                type: "success",
              });
              this.gerenstuts = false;
            });
          } else if (formName == "userJob") {
            let config = JSON.parse(JSON.stringify(this.userJob));
            let satrtTime = new Date(config.startDate);
            let sy = satrtTime.getFullYear();
            let sm = satrtTime.getMonth() + 1;
            let sd = satrtTime.getDate();
            config.startDate = sy + "-" + sm + "-" + sd;
            let endTime = new Date(config.endDate);
            let ey = endTime.getFullYear();
            let em = endTime.getMonth() + 1;
            let ed = endTime.getDate();
            config.endDate = ey + "-" + em + "-" + ed;
            this.$api.saveWantJob("post", config).then((res) => {
              this.getResumeDetail(this.isFromUpload);
              this.$message({
                message: res.code == 10200 ? "求职意向保存成功" : res.message,
                type: res.code == 10200 ? "success" : "error",
              });
              this.zhiweistuts = false;
            });
          } else if (formName == "workExp") {
            let config = JSON.parse(JSON.stringify(this.workExp));
            let satrtTime = new Date(config.startDate);
            let sy = satrtTime.getFullYear();
            let sm = satrtTime.getMonth() + 1;
            let sd = satrtTime.getDate();
            config.startDate = sy + "-" + sm + "-" + sd;
            let endTime = new Date(config.endDate);
            let ey = endTime.getFullYear();
            let em = endTime.getMonth() + 1;
            let ed = endTime.getDate();
            config.endDate = ey + "-" + em + "-" + ed;
            this.$api.saveWorkExp("post", config).then(() => {
              this.isUploadSaved.expSaved = true;
              this.getResumeDetail(this.isFromUpload);
              this.$message({
                message: "工作经历保存成功",
                type: "success",
              });
              this.workstuts = false;
            });
          } else if (formName == "education") {
            let config = JSON.parse(JSON.stringify(this.education));
            let satrtTime = new Date(config.startDate);
            let sy = satrtTime.getFullYear();
            let sm = satrtTime.getMonth() + 1;
            let sd = satrtTime.getDate();
            config.startDate = sy + "-" + sm + "-" + sd;
            let endTime = new Date(config.endDate);
            let ey = endTime.getFullYear();
            let em = endTime.getMonth() + 1;
            let ed = endTime.getDate();
            config.endDate = ey + "-" + em + "-" + ed;
            this.$api.saveEducation("post", config).then(() => {
              this.isUploadSaved.eduSaved = true;
              this.getResumeDetail(this.isFromUpload);
              this.$message({
                message: "教育经历保存成功",
                type: "success",
              });
              this.jiaoyustuts = false;
            });
          } else if (formName == "projectExp") {
            let config = JSON.parse(JSON.stringify(this.projectExp));
            let satrtTime = new Date(config.projectStartDate);
            let sy = satrtTime.getFullYear();
            let sm = satrtTime.getMonth() + 1;
            let sd = satrtTime.getDate();
            config.projectStartDate = sy + "-" + sm + "-" + sd;
            let endTime = new Date(config.projectEndDate);
            let ey = endTime.getFullYear();
            let em = endTime.getMonth() + 1;
            let ed = endTime.getDate();
            config.projectEndDate = ey + "-" + em + "-" + ed;
            this.$api.saveProjectExp("post", config).then(() => {
              this.isUploadSaved.projectSaved = true;
              this.getResumeDetail(this.isFromUpload);
              this.$message({
                message: "项目经历保存成功",
                type: "success",
              });
              this.xiangmustuts = false;
            });
          } else if (formName == "cert") {
            this.$api.saveCert("post", this.cert).then(() => {
              this.isUploadSaved.certSaved = true;
              this.getResumeDetail(this.isFromUpload);
              this.$message({
                message: "证书保存成功",
                type: "success",
              });
              this.zigestuts = false;
            });
          } else if (formName == "language") {
            this.$api.saveLanguage("post", this.language).then(() => {
              this.getResumeDetail(this.isFromUpload);
              this.$message({
                message: "外语能力保存成功",
                type: "success",
              });
              this.waiyustuts = false;
            });
          } else if (formName == "video") {
            this.$api.saveVideo("post", this.video).then(() => {
              this.getResumeDetail(this.isFromUpload);
              this.$message({
                message: "视频简历保存成功",
                type: "success",
              });
              this.shipingstuts = false;
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input__inner,
/deep/ .el-textarea__inner {
  border-radius: 0px;
}
/deep/ .el-button {
  border-radius: 0px;
}
.p-tit {
  color: #111111;
  position: relative;
  padding-left: 15px;
}
.p-tit:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 18px;
  top: 50%;
  background: #00bcff;
  left: 0px;
  transform: translate(0%, -50%);
}
.page-content {
  background-color: #ffffff;
  margin-top: 20px;
  padding-bottom: 20px;
  .person:hover {
    // transform: scale(1.01);
    // background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    .edit {
      display: flex !important;
      width: 22px;
      height: 18px;
    }
  }
  .person {
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    padding: 20px 30px;
    .p-pic {
      width: 64px;
      margin-right: 30px;
      img {
        width: 64px;
        height: 64px;
        border-radius: 50px;
      }
    }
    .p-info {
      display: flex;
      flex-direction: column;
      flex: 1;
      line-height: 1.7;
      .pi-one {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div:first-child {
          display: flex;
          align-items: center;
          span {
            color: #111111;
          }
          img {
            width: 24px;
            height: 24px;
            margin-left: 10px;
          }
        }
        div:last-child {
          font-size: 20px;
          .edit {
            width: 22px;
            height: 18px;
            display: none;
          }
        }
      }
      .pi-two {
        color: #666666;
      }
      .pi-three {
        color: #111111;
        display: flex;
        align-items: center;
        .zhiwei {
          width: 10px;
          height: 18px;
          margin-right: 10px;
        }
        .dianhua {
          width: 12px;
          height: 16px;
          margin-right: 10px;
          margin-left: 15px;
        }
        .youxiang {
          width: 15px;
          height: 12px;
          margin-right: 10px;
          margin-left: 15px;
        }
        .weixin {
          width: 20px;
          height: 16px;
          margin-right: 10px;
          margin-left: 15px;
        }
      }
    }
  }
}
.gonggong:hover {
  // transform: scale(1.01);
  // background-color: rgba(0, 0, 0, 0.3);
  .laji {
    display: flex !important;
  }
  .edit {
    display: flex !important;
  }
}
.gonggong {
  padding: 20px 30px;
  border-bottom: 1px solid #f3f3f3;
  .video {
    margin-top: 20px;
    .imgshowmain:hover {
      .el-icon-error {
        display: flex !important;
      }
    }
    .imgshowmain {
      margin-right: 30px;
      margin-top: 30px;
      position: relative;
      .video-avatar {
        width: 300px;
        height: 180px;
      }
      .video-avatar::-webkit-media-controls {
        display: none !important;
      }
      .videoBox {
        width: 300px;
        height: 180px;
        position: absolute;
        top: 0;
        left: 0;
      }
      img {
        width: 300px;
        height: 180px;
      }
      .bofang {
        position: absolute;
        width: 67px;
        height: 63px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .el-icon-error {
        width: 30px;
        height: 30px;
        top: 5px;
        right: 5px;
        position: absolute;
        font-size: 30px;
        color: #dddddd;
        display: none;
      }
    }
    img {
      width: 240px;
      height: 160px;
    }
  }
  .book {
    display: flex;
    align-items: center;
    margin-top: 20px;
    div:first-child {
      width: 300px;
      color: #111111;
    }
    div:last-child {
      color: #00bcff;
    }
    .lookmore:hover {
      cursor: pointer;
    }
  }
  .tit-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .caozuo:hover {
      cursor: pointer;
    }
    .caozuo {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        //margin-right: 6px;
      }
      color: #00bcff;
    }
  }
  .position {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .p-left {
      display: flex;
      align-items: center;
      //flex: 1;
      color: #111111;
      span {
        color: #666666;
        margin-left: 7px;
      }
      .time {
        margin-left: 80px;
        color: #111111;
      }
    }
    .p-center {
      display: flex;
      align-items: center;
      flex: 1;
      color: #111111;
    }
    .p-right:hover {
      cursor: pointer;
    }
    .p-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;

      .laji {
        display: none;
        width: 19px;
        height: 20px;
      }
      .edit {
        display: none;
        width: 22px;
        height: 18px;
        margin-right: 30px;
      }
    }
  }
  .q-other {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .qo-bqian {
      display: flex;
      flex-wrap: wrap;
      span {
        background-color: #eeeeee;
        color: #444444;
        padding: 2px 8px;
        margin: 0 10px 10px 0;
      }
    }
    .qo-time {
      display: flex;
      align-items: center;
      color: #111111;
      width: 460px;
      justify-content: flex-end;
      img {
        width: 40px;
        height: 40px;
        margin-right: 4px;
      }
      .dao {
        margin: 0 20px;
      }
    }
  }
  .miaoshu {
    margin-top: 24px;
    padding-bottom: 20px;
    color: #333333;
    font-size: 14px;
    line-height: 1.5;
  }
}

.qiuzhi {
  background-color: #dfe3ed;
  padding: 0px 40px;
  .p-tit {
    color: #111111;
    position: relative;
    padding-top: 30px;
  }
  .p-tit:before {
    position: absolute;
    content: "";
    width: 4px;
    height: 18px;
    top: 50%;
    background: #00bcff;
    left: -10px;
    transform: translate(0%, 35%);
  }
  .formbox {
    padding: 30px;
    // background-color: #000080;
    .demo-ruleForm {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .imgshowmain:hover {
        .el-icon-error {
          display: flex !important;
        }
      }
      .imgshowmain {
        margin-right: 30px;
        margin-top: 30px;
        position: relative;
        .video-avatar {
          width: 300px;
          height: 180px;
        }
        .video-avatar::-webkit-media-controls {
          display: none !important;
        }
        .videoBox {
          width: 300px;
          height: 180px;
          position: absolute;
          top: 0;
          left: 0;
        }
        img {
          width: 300px;
          height: 180px;
        }
        .bofang {
          position: absolute;
          width: 67px;
          height: 63px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .el-icon-error {
          width: 30px;
          height: 30px;
          top: 5px;
          right: 5px;
          position: absolute;
          font-size: 30px;
          color: #dddddd;
          display: none;
        }
      }
      .qux {
        background-color: #fff;
        border: none;
        width: 150px;
        height: 40px;
        color: #111;
        margin-left: -60px;
        margin-right: 10px;
      }
      .quxb {
        background-color: #fff;
        border: 1px solid #dddddd;
        width: 150px;
        height: 40px;
        color: #111;
        margin-left: -60px;
        margin-right: 10px;
      }
      .que {
        background-color: #04bdff;
        border: none;
        width: 150px;
        height: 40px;
      }
    }
  }
}

.gereninfo,
.qiuzhi {
  background-color: #ffffff;
  padding: 0px 40px;
  .p-tit {
    color: #111111;
    position: relative;
    padding-top: 30px;
  }
  .p-tit:before {
    position: absolute;
    content: "";
    width: 4px;
    height: 18px;
    top: 50%;
    background: #00bcff;
    left: -10px;
    transform: translate(0%, 35%);
  }
  .formbox {
    padding: 30px;
    // background-color: #000080;
    .demo-ruleForm {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .qux {
        background-color: #fff;
        border: none;
        width: 150px;
        height: 40px;
        color: #111;
        margin-left: -60px;
        margin-right: 10px;
      }
      .quxb {
        background-color: #fff;
        border: 1px solid #dddddd;
        width: 150px;
        height: 40px;
        color: #111;
        margin-left: -60px;
        margin-right: 10px;
      }
      .que {
        background-color: #04bdff;
        border: none;
        width: 150px;
        height: 40px;
      }
    }
  }
}
.uploaditem{
  background-color: #ffffff;
  padding: 20px 20px 0 40px;
  align-items: center;
  .item{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 22px;
    span{
      margin-right: 10px;
      color: red
    }
    .quxb {
      background-color: #fff;
      border: 1px solid #dddddd;
      width: 150px;
      height: 40px;
      color: #111;
      margin-right: 10px;
    }
    .que {
      background-color: #04bdff;
      border: none;
      width: 150px;
      height: 40px;
      margin-right: 10px;
    }
  }
}
</style>
