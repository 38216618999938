<template>
  <div class="">
    <div class="header-top font16">
      <div class="ht-left">我的在线简历</div>
      <div class="ht-right">
        <span @click="choosef">上传简历</span>
        <!-- changestuts(2) -->
        <span @click="down()">预览</span>
        <!--				<span @click="down()">下载</span>-->
        <span @click="output()">导出</span>
        <span @click="testApi()">测试</span>
      </div>
		</div>
		<!-- 编辑-简历中心 -->
		<div class="" v-if="pagestuts==1">
			<edfit @quchangenum="goChangeNum"></edfit>
		</div>
		<!-- 预览 -->
		<div class="" v-if="pagestuts==2">
			<preview @changenum="changenum"></preview>
		</div>

	</div>
</template>

<script>
	import Bus from '../../../common/bus.js';
	import edit from './editjl.vue';
	import preview from './preview.vue'
  import axios from "axios";

export default {
	components:{
		edit,
		preview,
	},
	data() {
		return {
			pagestuts:2
		};
	},
	created() {
	  // console.log("$information page ----- ### created!");
  },
	mounted() {
		window.addEventListener('yulanname', event => {
			// console.log('监听的值改变了',event.newValue)
		})
	},
	beforeDestroy() {
		localStorage.removeItem('weizhiname');
		localStorage.removeItem('yulanname');
	},
	methods: {
		choosef(){
			Bus.$emit('choosefstuts', true)
		},
		upresume(){
			Bus.$emit('resumestuts', true)
		},
		changestuts(val){
			// console.log(val)
			this.pagestuts=val;
		},
		changenum(e){
			this.pagestuts=e.num;
			localStorage.setItem('weizhiname',e.name)
			// console.log('去编辑',this.pagestuts,e.name)
		},
    goChangeNum(e){
			// console.log('编辑取消',e,e.name)
			this.pagestuts=e.num;
			localStorage.setItem('yulanname',e.name)
		},
		down(){
			this.$router.push('/previewresume');
		},
    output(){
      // 简历表格形式导出
      this.$api.getUserId("get").then((res) => {
        // console.log("下载简历， 获取用户ID值，通过ID遍历简历信息 ----- res = ", res);
        if(res.code === 10200) {
          let userId = res.data - 0;
          let testUrl = "";
          testUrl = axios.defaults.baseURL; // "http://192.168.10.202:8200/"; //模拟跨域地址
          testUrl = testUrl + "/user/cv/outputPreviewExcelModel?userId=" + userId;
          window.open(testUrl);
        }
      });
    },

    testApi(){
		  // html邮件测试
      this.$api.testMail("get", {address:"jianliang@youcan-group.com"}).then((res) => {
        console.log("test mail!!!! res: ", res);
      })
    },

    download(blobUrl) {
		  // console.log("url = ", blobUrl);
      const a = document.createElement('a');
      a.download = '个人简历模板' + '.xls';
      a.href = blobUrl;
      a.click();
    },
	}
};
</script>

<style lang="less" scoped>
.header-top {
	background-color: #444c5f;
	padding: 20px 30px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.ht-left {
	}
	.ht-right {
		span:hover{
			cursor: pointer;
		}
		span {
			background-color: #646d83;
			padding: 2px 10px;
			margin-left: 20px;
			border-radius: 2px;
		}
	}
}
</style>
