<template>
  <div class="pagecontent" ref="wrapper">
    <div class="gereninfo">
      <div class="p-tit font18" @click="goAnchor()">编辑个人信息</div>
      <div class="formbox">
        <el-form
          :model="dateForm"
          ref="dateForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="姓名："
            prop="name"
            :rules="[{ required: true, message: '年龄不能为空' }]"
          >
            <el-input
              v-model="dateForm.name"
              placeholder="请输入姓名"
              style="width: 260px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="出生年月111："
            prop="date"
            :rules="[{ required: true, message: '出生年月不能为空' }]"
          >
            <el-date-picker
              v-model="dateForm.date"
              :picker-options="pickerOptions"
              type="datetime"
              placeholder="出生年月"
              style="width: 260px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="性别:" prop="sex">
            <el-select
              v-model="dateForm.sex"
              placeholder="请选择性别"
              style="width: 260px"
            >
              <el-option label="男" value="nan"></el-option>
              <el-option label="女" value="nv"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="手机号码："
            prop="phone"
            :rules="[{ required: true, message: '请输入手机号码' }]"
          >
            <el-input
              v-model="dateForm.phone"
              placeholder="请输入手机号码"
              style="width: 260px"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="电子邮箱："
            prop="email"
            :rules="emailrules.email"
          >
            <el-input
              v-model="dateForm.email"
              placeholder="请输入电子邮箱"
              style="width: 650px"
            ></el-input>
          </el-form-item>
          <el-form-item
              label="微信号："
              prop="wxnum"
              :rules="[{ required: true, message: '请输入您的微信号' }]"
          >
            <el-input
              v-model="dateForm.wxnum"
              placeholder="请输入您的微信号"
              style="width: 260px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="qux" @click="quxiao('gerenname')">取消</el-button>
            <el-button
              class="que"
              type="primary"
              @click="submitForm('dateForm')"
              >保存</el-button
            >
          </el-form-item>
          <div class="font14" style="color: #999999; margin-left: 100px">
            填写微信号能让您与企业握手后更好的沟通
          </div>
        </el-form>
      </div>
    </div>
    <div class="qiuzhi">
      <div class="p-tit font18">编辑求职意向</div>
      <div class="formbox">
        <el-form
          :model="positionForm"
          ref="positionForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="职位名称："
            prop="pname"
            :rules="[{ required: true, message: '请输入职位名称' }]"
          >
            <el-input
              v-model="positionForm.pname"
              placeholder="请输入职位名称"
              style="width: 650px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="期望工作时间："
            prop="name"
            :rules="[{ required: true, message: '期望工作时间不能为空' }]"
          >
            <el-date-picker
              v-model="positionForm.time1"
              type="date"
              placeholder="选择日期"
              style="width: 315px; margin-right: 20px"
            >
            </el-date-picker>
            <el-date-picker
              v-model="positionForm.time2"
              type="date"
              placeholder="选择日期"
              style="width: 315px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="工作城市："
            prop="sheng"
            :rules="[{ required: true, message: '请选择工作城市' }]"
          >
            <el-select
              v-model="positionForm.sheng"
              placeholder="请选择省份"
              style="width: 315px; margin-right: 20px"
            >
              <el-option
                v-for="item in gzoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-select
              v-model="positionForm.city"
              placeholder="请选择城市"
              style="width: 315px"
            >
              <el-option
                v-for="item in gzoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="工作技能："
            prop="jineng"
            :rules="[{ required: true, message: '请选择工作技能' }]"
          >
            <!-- <el-select v-model="positionForm.jineng" placeholder="请选择工作技能" style="width: 260px;" >
							<el-option v-for="item in gzoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select> -->
            <el-input
              v-model="positionForm.jineng"
              placeholder="请选择工作技能"
              style="width: 260px"
              @click.native="choosejn()"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="工作经验："
            prop="jingyan"
            :rules="[{ required: true, message: '请选择工作经验' }]"
          >
            <el-select
              v-model="positionForm.jingyan"
              placeholder="请选择工作经验"
              style="width: 260px"
            >
              <el-option
                v-for="item in gzoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="期望薪资："
            prop="xinzi"
            :rules="[{ required: true, message: '期望薪资' }]"
          >
            <el-select
              v-model="positionForm.xinzi"
              placeholder="期望薪资"
              style="width: 260px"
            >
              <el-option
                v-for="item in gzoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button class="quxb" @click="quxiao()">取消</el-button>
            <el-button
              class="que"
              type="primary"
              @click="submitForm('positionForm')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="qiuzhi">
      <div class="p-tit font18" id="gzjl">编辑工作经历</div>
      <div class="formbox">
        <el-form
          :model="workForm"
          ref="workForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="公司名称："
            prop="gsname"
            :rules="[{ required: true, message: '请输入公司名称' }]"
          >
            <el-input
              v-model="workForm.pname"
              placeholder="请输入公司名称"
              style="width: 260px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="职位名称："
            prop="pname"
            :rules="[{ required: true, message: '请输入职位名称' }]"
          >
            <el-input
              v-model="workForm.pname"
              placeholder="请输入职位名称"
              style="width: 260px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="在职时间："
            prop="time2"
            :rules="[{ required: true, message: '在职时间不能为空' }]"
          >
            <el-date-picker
              v-model="workForm.time1"
              :picker-options="pickerOptions"
              type="date"
              placeholder="选择日期"
              style="width: 315px; margin-right: 20px"
              @change="datePickChecker('workExpTime')"
            >
            </el-date-picker>
            <el-date-picker
              v-model="workForm.time2"
              :picker-options="pickerOptions"
              type="date"
              placeholder="选择日期"
              style="width: 315px"
              @change="datePickChecker('workExpTime')"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="职位描述："
            prop="content"
            :rules="[{ required: true, message: '请输入职位描述' }]"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 8, maxRows: 10 }"
              show-word-limit
              placeholder="请输入职位描述~"
              v-model="workForm.content"
              style="width: 650px"
            ></el-input>
          </el-form-item>

          <div
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
              width: 100%;
              margin-right: 22px;
            "
          >
            <el-button class="quxb" @click="quxiao('gzjl')">取消</el-button>
            <el-button
              class="que"
              type="primary"
              @click="submitForm('workForm')"
              >保存</el-button
            >
          </div>
        </el-form>
      </div>
    </div>
    <div class="qiuzhi">
      <div class="p-tit font18" id="jyjl">编辑教育经历</div>
      <div class="formbox">
        <el-form
          :model="jiaoyuForm"
          ref="jiaoyuForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="学校名称："
            prop="gsname"
            :rules="[{ required: true, message: '请输入学校名称' }]"
          >
            <el-input
              v-model="jiaoyuForm.pname"
              placeholder="请输入学校名称"
              style="width: 260px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="专业名称："
            prop="pname"
            :rules="[{ required: true, message: '请输入专业名称' }]"
          >
            <el-input
              v-model="jiaoyuForm.pname"
              placeholder="请输入专业名称"
              style="width: 260px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="学历："
            prop="xueli"
            :rules="[{ required: true, message: '请选择学历' }]"
          >
            <el-select
              v-model="positionForm.xueli"
              placeholder="请选择学历"
              style="width: 650px"
            >
              <el-option
                v-for="item in gzoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="项目时间："
            prop="time2"
            :rules="[{ required: true, message: '项目时间不能为空' }]"
          >
            <el-date-picker
              v-model="jiaoyuForm.time1"
              :picker-options="pickerOptions"
              type="date"
              placeholder="选择日期"
              style="width: 315px; margin-right: 20px"
              @change="datePickChecker('eduExpTime')"
            >
            </el-date-picker>
            <el-date-picker
              v-model="jiaoyuForm.time2"
              :picker-options="pickerOptions"
              type="date"
              placeholder="选择日期"
              style="width: 315px"
              @change="datePickChecker('eduExpTime')"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="项目描述："
            prop="content"
            :rules="[{ required: true, message: '请描述项目情况' }]"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 8, maxRows: 10 }"
              show-word-limit
              placeholder="请描述项目情况~"
              v-model="jiaoyuForm.content"
              style="width: 650px"
            ></el-input>
          </el-form-item>

          <div
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
              width: 100%;
              margin-right: 22px;
            "
          >
            <el-button class="quxb" @click="quxiao('jyjl')">取消</el-button>
            <el-button
              class="que"
              type="primary"
              @click="submitForm('jiaoyuForm')"
              >保存</el-button
            >
          </div>
        </el-form>
      </div>
    </div>
    <div class="qiuzhi">
      <div class="p-tit font18" id="xmjl">编辑项目经历</div>
      <div class="formbox">
        <el-form
          :model="xiangmuForm"
          ref="xiangmuForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="项目名称："
            prop="gsname"
            :rules="[{ required: true, message: '请输入项目名称' }]"
          >
            <el-input
              v-model="xiangmuForm.pname"
              placeholder="请输入项目名称"
              style="width: 260px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="项目链接："
            prop="pname"
            :rules="[{ required: true, message: '请输入项目链接' }]"
          >
            <el-input
              v-model="xiangmuForm.pname"
              placeholder="请输入项目链接"
              style="width: 260px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="项目技能："
            prop="xueli"
            :rules="[{ required: true, message: '请选择项目技能' }]"
          >
            <el-select
              v-model="positionForm.xueli"
              placeholder="请选择项目技能"
              style="width: 650px"
            >
              <el-option
                v-for="item in gzoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="在校时间："
            prop="time2"
            :rules="[{ required: true, message: '在校时间不能为空' }]"
          >
            <el-date-picker
              v-model="xiangmuForm.time1"
              :picker-options="pickerOptions"
              type="date"
              placeholder="选择日期"
              style="width: 315px; margin-right: 20px"
              @change="datePickChecker('projectExpTime')"
            >
            </el-date-picker>
            <el-date-picker
              v-model="xiangmuForm.time2"
              :picker-options="pickerOptions"
              type="date"
              placeholder="选择日期"
              style="width: 315px"
              @change="datePickChecker('projectExpTime')"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="在校情况："
            prop="content"
            :rules="[{ required: true, message: '请描述在校情况' }]"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 8, maxRows: 10 }"
              show-word-limit
              placeholder="请描述在校情况~~"
              v-model="xiangmuForm.content"
              style="width: 650px"
            ></el-input>
          </el-form-item>

          <div
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
              width: 100%;
              margin-right: 22px;
            "
          >
            <el-button class="quxb" @click="quxiao('xmjl')">取消</el-button>
            <el-button
              class="que"
              type="primary"
              @click="submitForm('xiangmuForm')"
              >保存</el-button
            >
          </div>
        </el-form>
      </div>
    </div>

    <div class="qiuzhi">
      <div class="p-tit font18" id="zgzs">编辑证书</div>
      <div class="formbox">
        <el-form
          :model="bookForm"
          ref="bookForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="证书名称："
            prop="name"
            :rules="[{ required: true, message: '请选择证书名称' }]"
          >
            <el-select
              v-model="bookForm.name"
              placeholder="请选择证书名称"
              style="width: 650px"
            >
              <el-option
                v-for="item in gzoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="图片描述:"
            prop="imgurl"
            :rules="[{ required: true, message: '请上传图片描述' }]"
          >
            <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="updialogVisible"
              ><img width="100%" :src="bookForm.imgurl" alt=""
            /></el-dialog>
            <div class="font14" style="color: #999999">
              只能上传jpg/png格式文件，文件不能超过3M
            </div>
          </el-form-item>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
              width: 100%;
              margin-right: 22px;
            "
          >
            <el-button class="quxb" @click="quxiao('zgzs')">取消</el-button>
            <el-button
              class="que"
              type="primary"
              @click="submitForm('bookForm')"
              >保存</el-button
            >
          </div>
        </el-form>
      </div>
    </div>
    <div class="qiuzhi">
      <div class="p-tit font18" id="spjl">编辑视频简历</div>
      <div class="formbox">
        <el-form
          :model="videoForm"
          ref="videoForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="标题："
            prop="name"
            :rules="[{ required: true, message: '请输入视频标题' }]"
          >
            <el-input
              v-model="videoForm.name"
              placeholder="请输入视频标题"
              style="width: 650px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="图片描述:"
            prop="imgurl"
            :rules="[{ required: true, message: '请上传图片描述' }]"
          >
            <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="updialogVisible"
              ><img width="100%" :src="videoForm.imgurl" alt=""
            /></el-dialog>
          </el-form-item>
          <el-form-item
            label="介绍："
            prop="content"
            :rules="[{ required: true, message: '请描述简介~' }]"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 8, maxRows: 10 }"
              show-word-limit
              placeholder="请描述简介~"
              v-model="videoForm.content"
              style="width: 650px"
            ></el-input>
          </el-form-item>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
              width: 100%;
              margin-right: 22px;
            "
          >
            <el-button class="quxb" @click="quxiao('spjl')">取消</el-button>
            <el-button
              class="que"
              type="primary"
              @click="submitForm('videoForm')"
              >保存</el-button
            >
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "../../../common/bus.js";

export default {
  components: {},
  data() {
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error("邮箱不能为空"));
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的邮箱格式"));
        }
      }, 100);
    };
    return {
      // 日期限制
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7 // 加- 8.64e7则表示包当天
        },
      },
      emailrules: {
        email: [
          { validator: checkEmail, required: true, trigger: "blur" },
          { required: true, message: "请输入电子邮箱" },
        ],
      },
      dateForm: {
        name: "",
        date: "",
        sex: "",
        phone: "",
        email: "",
        wxnum: "",
      },
      positionForm: {
        pname: "",
        time1: "",
        time2: "",
        content: "",
      },
      gzoptions: [
        {
          value: "09:00",
          label: "09:00",
        },
        {
          value: "10:00",
          label: "10:00",
        },
      ],
      workForm: {
        gsname: "",
        pname: "",
        time1: "",
        time2: "",
        sheng: "",
        city: "",
        jineng: "",
        jingyan: "",
        xinzi: "",
      },
      jiaoyuForm: {
        pname: "",
        time1: "",
        time2: "",
        content: "",
        xueli: "",
      },
      xiangmuForm: {
        pname: "",
        time1: "",
        time2: "",
        content: "",
        xueli: "",
      },
      bookForm: {
        name: "",
        imgurl: "",
      },
      updialogVisible: false,
      videoForm: {
        name: "",
        imgurl: "",

        content: "",
      },
    };
  },
  created() {
    // setTimeout(this.goAnchor(),1000);
  },
  mounted() {
    // this.goAnchor()
    let namees = localStorage.getItem("weizhiname");
    // console.log("去指定位置999", namees);
    document.getElementById(namees).scrollIntoView();
    // Bus.$on('ttototooto', data => {
    // 	console.log('去指定位置',data)
    // 	document.getElementById('idName').scrollIntoView()
    // });
  },
  methods: {
    // goAnchor(){
    // 	console.log('去指定位置=========')
    // 	document.getElementById("idName").scrollIntoView();
    // 	//或者
    // 	document.querySelector("#idName").scrollIntoView();
    // 	//或者
    // 	this.$refs.refName.scrollIntoView();
    // },
    datePickChecker(type) {  // 日期入例检测，结束时间不能晚于开始时间
      var start;
      var end;
      let warnFlag = false;
      if(type === 'workExpTime'){
        start = (new Date(this.workForm.time1) * 1000) / 1000;
        end = (new Date(this.workForm.time2) * 1000) / 1000;
        if (start && end && start > end) {
          this.workForm.time2 = this.workForm.time1;
          warnFlag = true;
        }
      }
      if(type === 'eduExpTime'){
        start = (new Date(this.jiaoyuForm.time1) * 1000) / 1000;
        end = (new Date(this.jiaoyuForm.time2) * 1000) / 1000;
        if (start && end && start > end) {
          this.jiaoyuForm.time2 = this.jiaoyuForm.time1;
          warnFlag = true;
        }
      }
      if(type === 'projectExpTime'){
        start = (new Date(this.xiangmuForm.time1) * 1000) / 1000;
        end = (new Date(this.xiangmuForm.time2) * 1000) / 1000;
        if (start && end && start > end) {
          this.xiangmuForm.time2 = this.xiangmuForm.time1;
          warnFlag = true;
        }
      }
      if (warnFlag) {
        this.$message({
          message: "结束时间小于开始时间！",
          type: "warning",
        });
      }
    },
    quxiao(name) {
      let data = {
        num: 2,
        name: name,
      };
      this.$emit("goChangeNum", data);
    },
    choosejn() {
      Bus.$emit("jinengstuts", true);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.jubaoForm.imgurl = file.url;
      this.updialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input__inner,
/deep/ .el-textarea__inner {
  border-radius: 0px;
}
/deep/ .el-button {
  border-radius: 0px;
}
/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
  border-radius: 0px;
}
/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 0px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 0px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 0px;
}
/deep/ .avatar {
  width: 80px;
  height: 80px;
  border-radius: 0px;
}
.pagecontent {
  margin-top: 20px;

  .gereninfo,
  .qiuzhi {
    background-color: #dfe3ed;
    padding: 0px 40px;
    .p-tit {
      color: #111111;
      position: relative;
      padding-top: 30px;
    }
    .p-tit:before {
      position: absolute;
      content: "";
      width: 4px;
      height: 18px;
      top: 50%;
      background: #00bcff;
      left: -10px;
      transform: translate(0%, 35%);
    }
    .formbox {
      padding: 30px;
      // background-color: #000080;
      .demo-ruleForm {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .qux {
          background-color: #fff;
          border: none;
          width: 150px;
          height: 40px;
          color: #111;
          margin-left: -60px;
          margin-right: 10px;
        }
        .quxb {
          background-color: #fff;
          border: 1px solid #dddddd;
          width: 150px;
          height: 40px;
          color: #111;
          margin-left: -60px;
          margin-right: 10px;
        }
        .que {
          background-color: #04bdff;
          border: none;
          width: 150px;
          height: 40px;
        }
      }
    }
  }
  .qiuzhi {
    background-color: #ffffff;
    border-bottom: 1px solid #dddddd;
  }
}
</style>
